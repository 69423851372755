body{
  /* overscroll-behavior: none; */
}

.max-lines-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
  white-space: nowrap;
}
.max-lines-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: nowrap;
}

#controls {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 5px 10px 5px 5px;
  max-width: 800px;
}

#controls #buttons {
  display: flex;
  flex-direction: row;
}

#controls #volume-container {
  display: flex;
  align-items: center;
}

#controls #time {
  display: flex;
  align-items: center;
  padding: 5px;
}

#controls #sounds {
  width: 100%;
}

.shake-component {
  animation: shake 1s;
  animation-iteration-count: infinite;
}

.dragging-over {
  position: relative;
}

.dragging-over::after {
  content: "Arraste o arquivo aqui";
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 42px;
  font-weight: bold;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
  background-color: #dfdfdfdd;
}
.file-preview {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 42px;
  font-weight: bold;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
  background-color: #ffffff;
}

@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(2deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-2deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  80% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  90% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  100% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
}

.dropdown-arrow::after {
  content: "";
  position: absolute;
  right: 0px !important;
  top: 0 !important;
  margin-top: -8px !important;
  margin-right: 8px !important;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid #fff;
  transform: rotate(180deg);
}

.notifications-icon:hover {
  color: #7950f1;
}


.conversation-box-text a{
  color: #EFEFEF;
  font-weight: bold;
}

.dragging-over {
  position: relative;
}

.dragging-over::after {
  content: "Arraste o arquivo aqui";
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 42px;
  font-weight: bold;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 2;
  background-color: #dfdfdfdd;
}